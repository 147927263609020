<template>
  <section id="master-group-belanja">
    <b-row>
      <b-col
        md="8"
        cols="12"
        class="mx-auto"
      >
        <b-card>

          <div class="d-flex justify-content-between align-items-center mb-1">
            <h3>
              {{ ticketData.subject }}
            </h3>
            <b-badge
              variant="light-info"
            >
              {{ ticketData.category }}
            </b-badge>
          </div>

          <b-list-group>
            <b-list-group-item
              :key="ticketData.helpdesk_id"
              class="flex-column align-items-start mb-1"
            >
              <div class="d-flex w-100 justify-content-between pb-50 border-bottom-secondary">
                <div class="font-weight-bold">
                  {{ ticketData.author }} &middot; {{ ticketData.instansi }}
                </div>
                <small class="text-primary">{{ ticketData.created_on | formatDate }}</small>
              </div>
              <b-card-text class="my-1">
                <div
                  class="help-content"
                  v-html="ticketData.message"
                />
              </b-card-text>
            </b-list-group-item>
          </b-list-group>

          <h3 class="mb-1">
            Replies
          </h3>

          <b-list-group class="mb-1">
            <b-list-group-item
              v-for="dt in ticketData.reply"
              :key="dt.helpdesk_id"
              class="flex-column align-items-start"
            >
              <div class="d-flex w-100 justify-content-between mb-1 pb-50 border-bottom-secondary">
                <div class="font-weight-bold">
                  {{ dt.author }}
                </div>
                <small class="text-primary">{{ dt.created_on | formatDate }}</small>
              </div>
              <b-card-text>
                <div
                  class="help-content"
                  v-html="dt.message"
                />
              </b-card-text>
            </b-list-group-item>
          </b-list-group>

          <template v-if="ticketData.open !== 'close' && (userData.group_id < 100 || userData.fullname === ticketData.author)">
            <validation-observer ref="simpleRules">
              <b-form @submit.prevent="validationForm">
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label="Balasan Anda"
                      label-for="vi-balasan"
                    >
                      <quill-editor
                        id="vi-balasan"
                        v-model="reply"
                        :options="editorOption"
                        class="mb-1"
                        required
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="12"
                    class="d-flex justify-content-between align-items-center"
                  >
                    <b-button
                      type="submit"
                      variant="primary"
                    >
                      Kirim Balasan
                    </b-button>
                    <b-button
                      variant="warning"
                      @click.prevent="closeTicket"
                    >
                      Tutup Tiket
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>

            <template v-if="alert">
              <b-alert
                :variant="alertDetail.variant"
                class="mt-1"
                show
              >
                <h4 class="alert-heading">
                  {{ alertDetail.title }}
                </h4>
                <div
                  v-if="alertDetail.variant === 'danger'"
                  class="alert-body"
                >
                  <ul v-if="typeof alertDetail.text === 'object'">
                    <li
                      v-for="(item, index) in alertDetail.text"
                      :key="index"
                    >
                      {{ index }}:
                      <span
                        v-for="(child, indexs) in item"
                        :key="indexs"
                      >
                        {{ child }}
                      </span>
                    </li>
                  </ul>
                  <template v-else>
                    {{ alertDetail.text }}
                  </template>
                </div>
                <div
                  v-else
                  class="alert-body"
                >
                  {{ alertDetail.text }}
                </div>
              </b-alert>
            </template>
          </template>
        </b-card>
      </b-col>
      <!-- post -->
    </b-row>
  </section>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  BForm, BRow, BButton, BCol, BCard, BFormGroup, BAlert, VBModal,
  BCardText, BListGroup, BListGroupItem, BBadge,
} from 'bootstrap-vue'

import { quillEditor } from 'vue-quill-editor'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BBadge,
    BForm,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BCardText,
    BButton,
    BAlert,
    BCard,
    BRow,
    BCol,
    ValidationObserver,
    quillEditor,
  },
  setup() {
    const toast = useToast()

    const editorOption = {
      theme: 'snow',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['clean', 'link', 'image'],
        ],
      },
      placeholder: 'Balasan Anda',
    }

    return {
      toast,
      editorOption,
    }
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      required,
      isLoading: false,
      userData: getUserData(),
      referenceData: [],
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
      helpdesk_id: '',
      ticketData: {},
      reply: '',
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.helpdesk_id = Number(this.$route.params.id)
      this.getReference()
    })
  },
  methods: {
    closeTicket() {
      this.$swal({
        title: 'Tutup Tiket?',
        text: 'Aksi ini tidak dapat dikembalikan.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya, tutup!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post(`/helpdesk/close-ticket?token=${localStorage.getItem('userToken')}`, {
            helpdesk_id: this.$route.params.id,
          })
            .then(res => {
              if (res.data.status === 'success') {
                this.alert = false
                this.$swal({
                  icon: 'success',
                  title: 'Tiket Ditutup!',
                  text: 'Tiket berhasil ditutup.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                }).then(resp => {
                  if (resp.value) {
                    this.$router.push({ name: 'pages-helpdesk' })
                  }
                })
              }
            })
            .catch(error => {
              this.alert = true
              this.alertDetail = {
                variant: 'danger',
                title: 'Error Found',
                text: (error.response.data.error !== '') ? error.response.data.error : error,
              }
            })
        }
      })
    },
    tambahTiket() {
      this.$refs['modal-tambah-tiket'].show()
    },
    validationForm() {
      if (!this.reply) {
        this.alert = true
        this.alertDetail = {
          variant: 'danger',
          title: 'Error Found',
          text: 'Kolom Balasan Tidak Boleh Kosong',
        }
      } else {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.saveReply()
          }
        })
      }
    },
    saveReply() {
      document.getElementById('loading-bg').style.display = 'block'
      const params = {
        message: this.reply,
      }
      this.$http.post(`/helpdesk/reply-ticket?token=${localStorage.getItem('userToken')}&helpdesk_id=${this.helpdesk_id}`, params)
        .then(res => {
          if (res.data.status === 'success') {
            this.alert = false
            this.reply = ''
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Reply Berhasil Disimpan',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            }, 1500)
            this.getReference()
            document.getElementById('loading-bg').style.display = 'none'
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.error !== '') ? error.response.data.error : error,
          }
        })
    },
    searchReset() {
      this.keyword = ''
      this.updateParams({ keyword: '' })
      this.loadItems()
    },
    catFilter(val) {
      this.category_id = val
      if (this.category_id !== '') {
        const params = [{
          column: 'helpdesk_category_id',
          value: val,
        }]
        this.updateParams({
          filters: params,
        })
      } else {
        delete this.serverParams.filters
      }
      this.loadItems()
    },
    getReference() {
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.get('/helpdesk/get-ticket', {
        params: {
          token: localStorage.getItem('userToken'),
          helpdesk_id: this.$route.params.id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            document.getElementById('loading-bg').style.display = 'none'
            this.ticketData = res.data.data
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';

.help-content img {
  max-width:100%;
}
</style>
